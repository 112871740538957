var render = function () {
  var _vm$loanData$appData, _vm$loanData$appData2, _vm$loanData$appData3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.SettlementForm ? _c('div', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isDialogLoading,
      expression: "isDialogLoading"
    }],
    staticClass: "loan-details-form"
  }, [_vm.shouldDisplaySuccessCpdAlert ? _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light bg-primary mb-4 alertbox",
    staticStyle: {
      "background": "rgba(0, 184, 73, 0.1)",
      "border-radius": "4px"
    },
    attrs: {
      "role": "alert",
      "data-selector": "st-cpd-success-alert"
    }
  }, [false ? _c('span', {
    staticClass: "rounded-fifty border border-dark order-label text-center text-xs mr-4"
  }, [_vm._v("1")]) : _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "cpd-alert-title"
    }
  }, [_vm._v(_vm._s(_vm.SUCCESS_TITLE_FOR_CREDIT_PROPSAL))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "cpd-alert-desc"
    }
  }, [_vm._v(_vm._s(_vm.SUCCESS_DESCRIPTION_FOR_CREDIT_PROPSAL) + "\n          " + _vm._s(_vm.format(new Date(_vm.loanData.appData.loanDetails.creditProposalDisclosureSentAt), 'dd/MM/yyyy hh:mm aaa')))])]), _vm._v(" "), _c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "type": "primary",
      "plain": "",
      "data-selector": "cpd-alert-view-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.viewAssetment(_vm.LABEL_CREDIT_PROPOSAL_DISCLOSURE);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_VIEW))])], 1)]) : _vm._e(), _vm._v(" "), _vm.shouldDisplayCpdAlert ? _c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light mb-4 mt-4 alertbox",
    staticStyle: {
      "background": "rgba(247, 173, 30, 0.1)",
      "border-radius": "4px"
    },
    attrs: {
      "role": "alert",
      "data-selector": "st-cpd-warning-alert"
    }
  }, [_c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/alert-triangle.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "cpd-alert-title"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CREDIT_PROPOSAL_DISCLOSURE))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "cpd-alert-desc"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CREDIT_PROPOSAL_DISCLOSURE_DETAILS) + "\n      ")])]), _vm._v(" "), _c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "data-selector": "st-cpd-review-send-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.reviewAndSendProposal(_vm.LABEL_CREDIT_PROPOSAL_DISCLOSURE);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_REVIEW_AND_SEND_PROPOSAL))])], 1) : _vm._e(), _vm._v(" "), !_vm.shouldDisplaySettlementBanner ? _c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light bg-primary mb-4 alertbox",
    staticStyle: {
      "background": "rgba(19, 181, 232, 0.1)",
      "border-radius": "4px"
    },
    attrs: {
      "role": "alert",
      "data-selector": "st-settlement-info-banner"
    }
  }, [_c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/info.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "st-settlement-alert-title"
    }
  }, [_vm._v(_vm._s(_vm.INFO_TITLE_FOR_SETTLEMENT))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "st-settlement-alert-subtitle"
    }
  }, [_vm._v(_vm._s(_vm.isCommercial ? _vm.INFO_DESCRIPTION_FOR_SETTLEMENT_COMMERCIAL : _vm.INFO_DESCRIPTION_FOR_SETTLEMENT) + "\n      ")])]), _vm._v(" "), _c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "type": "primary",
      "disabled": _vm.shouldDisabledSubmitSettleButton,
      "loading": _vm.submitSettlementBtnLoading,
      "data-selector": "mark-application-settled-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.submitSettlement();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_MARK_APPLICATION_AS_SETTLED))])], 1) : _vm._e(), _vm._v(" "), _vm.shouldDisplaySettlementBanner ? _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light bg-primary mb-4 alertbox",
    staticStyle: {
      "background": "rgba(0, 184, 73, 0.1)",
      "border-radius": "4px"
    },
    attrs: {
      "role": "alert",
      "data-selector": "st-settlement-success-banner"
    }
  }, [_c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "st-settlement-alert-title"
    }
  }, [_vm._v(_vm._s(_vm.INFO_TITLE_FOR_SETTLEMENT))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "st-settlement-alert-subtitle"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_APPLICATION_SETTLED_AT) + "\n          " + _vm._s(_vm.format(new Date(_vm.loanData.appData.loanDetails.settlement.settledAt), 'dd/MM/yyyy hh:mm aaa')) + "\n        ")])])])]) : _vm._e(), _vm._v(" "), _vm.isSettled ? _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light bg-primary mb-4 alertbox",
    class: _vm.xeroInvoiceBannerType == 'SUCCESS' ? 'banner-success' : 'banner-warning',
    attrs: {
      "role": "alert"
    }
  }, [_vm.xeroInvoiceBannerType == 'SUCCESS' ? _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": "okay"
    }
  }) : _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/alert-triangle.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "st-settlement-alert-title"
    }
  }, [_vm._v(_vm._s(_vm.INFO_TITLE_FOR_ACCOUNT_INVOICE))]), _vm._v(" "), _vm.xeroInvoiceBannerType == _vm.XERO_INVOICE_STATUS.SUCCESS ? _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "st-settlement-alert-subtitle"
    }
  }, [_vm._v("Accounts invoice is created at\n          " + _vm._s(_vm.format(new Date((_vm$loanData$appData = _vm.loanData.appData) === null || _vm$loanData$appData === void 0 ? void 0 : _vm$loanData$appData.tpInvoiceCreatedAt), 'dd/MM/yyyy hh:mm aaa')) + "\n        ")]) : _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "st-settlement-alert-subtitle"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.GET_XERO_INVOICE_ERROR_BANNER_MESSAGE(_vm.xeroInvoiceBannerType, _vm.XERO_INVOICE_TPYE_ACCOUNTS, _vm.getBusinessName, _vm.xeroInvoiceNumber))
    }
  })]), _vm._v(" "), [_vm.XERO_INVOICE_STATUS.ERROR_UNKNOWN, _vm.XERO_INVOICE_STATUS.LENDER_DOES_NOT_EXIST].includes(_vm.xeroInvoiceBannerType) ? _c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "plain": ""
    },
    on: {
      "click": function ($event) {
        return _vm.handleRetryCreateInvoice();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_RETRY))]) : _vm._e()], 1)]) : _vm._e(), _vm._v(" "), _vm.isSettled && _vm.insuranceXeroInvoiceBannerType != _vm.XERO_INVOICE_STATUS.NOT_AVAILABLE ? _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light bg-primary mb-4 alertbox",
    class: _vm.insuranceXeroInvoiceBannerType == 'SUCCESS' ? 'banner-success' : 'banner-warning',
    attrs: {
      "role": "alert"
    }
  }, [_vm.insuranceXeroInvoiceBannerType == _vm.XERO_INVOICE_STATUS.SUCCESS ? _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": "okay"
    }
  }) : _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/alert-triangle.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "st-settlement-alert-title"
    }
  }, [_vm._v(_vm._s(_vm.INFO_TITLE_FOR_INSURANCE_INVOICE))]), _vm._v(" "), _vm.insuranceXeroInvoiceBannerType == _vm.XERO_INVOICE_STATUS.SUCCESS ? _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "st-settlement-alert-subtitle"
    }
  }, [_vm._v("Insurance invoice is created at\n          " + _vm._s(_vm.format(new Date((_vm$loanData$appData2 = _vm.loanData.appData) === null || _vm$loanData$appData2 === void 0 ? void 0 : _vm$loanData$appData2.tpInsuranceInvoiceCreatedAt), 'dd/MM/yyyy hh:mm aaa')) + "\n        ")]) : _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "st-settlement-alert-subtitle"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.GET_XERO_INVOICE_ERROR_BANNER_MESSAGE(_vm.insuranceXeroInvoiceBannerType, _vm.XERO_INVOICE_TPYE_INSURANCE, _vm.getBusinessName, _vm.insuranceXeroInvoiceNumber))
    }
  })]), _vm._v(" "), _vm.insuranceXeroInvoiceBannerType == _vm.XERO_INVOICE_STATUS.ERROR_UNKNOWN ? _c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "plain": ""
    },
    on: {
      "click": function ($event) {
        return _vm.handleRetryCreateInvoice(_vm.XERO_INVOICE_TPYE_INSURANCE);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_RETRY))]) : _vm._e()], 1)]) : _vm._e(), _vm._v(" "), _vm.isSettled && _vm.warrantyXeroInvoiceBannerType != _vm.XERO_INVOICE_STATUS.NOT_AVAILABLE ? _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light bg-primary mb-4 alertbox",
    class: _vm.warrantyXeroInvoiceBannerType == 'SUCCESS' ? 'banner-success' : 'banner-warning',
    attrs: {
      "role": "alert"
    }
  }, [_vm.warrantyXeroInvoiceBannerType == _vm.XERO_INVOICE_STATUS.SUCCESS ? _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/ok.svg"),
      "alt": "okay"
    }
  }) : _c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/alert-triangle.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "st-settlement-alert-title"
    }
  }, [_vm._v(_vm._s(_vm.INFO_TITLE_FOR_WARRANTY_INVOICE))]), _vm._v(" "), _vm.warrantyXeroInvoiceBannerType == _vm.XERO_INVOICE_STATUS.SUCCESS ? _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "st-settlement-alert-subtitle"
    }
  }, [_vm._v("Warranty invoice is created at\n          " + _vm._s(_vm.format(new Date((_vm$loanData$appData3 = _vm.loanData.appData) === null || _vm$loanData$appData3 === void 0 ? void 0 : _vm$loanData$appData3.tpWarrantyInvoiceCreatedAt), 'dd/MM/yyyy hh:mm aaa')) + "\n        ")]) : _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "st-settlement-alert-subtitle"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.GET_XERO_INVOICE_ERROR_BANNER_MESSAGE(_vm.warrantyXeroInvoiceBannerType, _vm.XERO_INVOICE_TPYE_WARRANTY, _vm.getBusinessName, _vm.warrantyXeroInvoiceNumber))
    }
  })]), _vm._v(" "), _vm.warrantyXeroInvoiceBannerType == _vm.XERO_INVOICE_STATUS.ERROR_UNKNOWN ? _c('el-button', {
    staticClass: "ml-auto",
    attrs: {
      "plain": ""
    },
    on: {
      "click": function ($event) {
        return _vm.handleRetryCreateInvoice(_vm.XERO_INVOICE_TPYE_WARRANTY);
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_RETRY))]) : _vm._e()], 1)]) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "flex"
  }, [_c('el-card', {
    staticClass: "width-78 application-form pb-child-none mb-4 settlement-Checklist"
  }, [_c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-bolder mb-1"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_SETTLEMENT_AGENT))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1 settlement-user",
    class: {
      'summary-tag': _vm.settlementUser
    },
    attrs: {
      "data-selector": "assigned-settlement-agent"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.settlementUser || '--') + "\n            ")])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-bolder mb-1"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_ASSIGNED_AT))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "settlement-agent-assigned-at"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.assignedToSettlementUser ? _vm.format(new Date(_vm.assignedToSettlementUser), 'dd/MM/yyyy hh:mm aaa') : '--') + "\n            ")])])]), _vm._v(" "), _c('el-col', {
    attrs: {
      "sm": 24,
      "lg": 8
    }
  }, [_c('div', [_c('p', {
    staticClass: "text-xs font-bolder mb-1"
  }, [_vm._v(_vm._s(_vm.LABEL_SETTLEMENT_ETA))])]), _vm._v(" "), _c('div', [_c('p', {
    staticClass: "text-sm mt-1",
    attrs: {
      "data-selector": "st-settlement-eta"
    }
  }, [_vm._v("\n              " + _vm._s(_vm.settlementETA ? _vm.format(new Date(_vm.settlementETA), 'dd/MM/yyyy') : '--') + "\n              "), _vm.shouldShowEditSettlementETA ? _c('el-button', {
    staticClass: "btn-default-color font-bolder ml-1",
    attrs: {
      "type": "text",
      "plain": "",
      "data-selector": "st-settlement-eta-edit-link"
    },
    on: {
      "click": function ($event) {
        return _vm.openCloseSettlementETADialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.SETTLEMENT_ETA_EDIT_LINK))]) : _vm._e()], 1)])])], 1)], 1), _vm._v(" "), _c('el-card', {
    staticClass: "width-22 ml-4 mb-4"
  }, [_c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    staticClass: "status-detail-wrapper",
    class: {
      'disable-dropdown': !_vm.isPreSettlementStatus
    },
    attrs: {
      "xs": 24,
      "md": 7
    }
  }, [_c('el-form', {
    staticClass: "demo-form-inline flex flex-wrap items-center",
    attrs: {
      "inline": true
    }
  }, [_c('el-form-item', {
    staticClass: "font-extrabold",
    attrs: {
      "label": "Settlement status",
      "required": "true",
      "data-selector": "st-settlement-status-input-container"
    }
  }, [_c('el-select', {
    attrs: {
      "filterable": "",
      "placeholder": "--",
      "value": _vm.settlementStatus,
      "disabled": !_vm.isPreSettlementStatus || _vm.isSettled
    },
    on: {
      "change": _vm.handleChange
    }
  }, [_c('div', {
    attrs: {
      "data-selector": "st-settlement-status-options-container"
    }
  }, _vm._l(_vm.CUSTOM_SETTLEMENT_APPLICATION_STATUSES, function (data) {
    return _c('el-option', {
      key: data.value,
      attrs: {
        "label": data.label,
        "value": data.value,
        "disabled": data.isDisabled
      }
    });
  }), 1)])], 1)], 1)], 1)], 1)], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "flex"
  }, [_c('el-card', {
    staticClass: "width-78 application-form pb-child-none mb-4 settlement-Checklist"
  }, [_c('div', {
    staticClass: "m-4"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_vm.hasSettlementChecklistChecked ? _c('div', {
    staticClass: "el-alert el-alert--primary text-black is-light bg-primary mb-6 alertbox",
    staticStyle: {
      "background": "rgba(247, 173, 30, 0.1)",
      "border-radius": "4px"
    },
    attrs: {
      "role": "alert",
      "data-selector": "sett-checklist-not-completed-alert"
    }
  }, [_c('img', {
    staticClass: "mr-4",
    attrs: {
      "src": require("@/assets/icons/alert-docs.svg"),
      "alt": "okay"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "el-alert__content"
  }, [_c('span', {
    staticClass: "el-alert__title is-bold",
    attrs: {
      "data-selector": "sett-checklist-alert-title"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_SETTLEMENT_CHECKLIST_NOT_COMPLETED))]), _vm._v(" "), _c('p', {
    staticClass: "el-alert__description",
    attrs: {
      "data-selector": "sett-checklist-alert-subtitle"
    }
  }, [_vm._v(_vm._s(_vm.settlementChecklistCheckedCount) + "/" + _vm._s(_vm.settlementChecklist.length) + "\n                " + _vm._s(_vm.LABEL_FOR_CHECKLIST_COMPLETED) + "\n              ")])])]) : _vm._e()])]), _vm._v(" "), _c('div', {
    staticClass: "items-center flex justify-between",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_SETTLEMENT_CHECKLIST) + "\n        ")])]), _vm._v(" "), _c('div', [_c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, _vm._l(_vm.settlementChecklist, function (data, index) {
    return _c('el-col', {
      key: index,
      attrs: {
        "sm": 24,
        "lg": 12
      }
    }, [_c('div', {
      staticClass: "el-alert text-black is-light bg-primary alertbox",
      staticStyle: {
        "background": "rgb(255 255 255 / 4%)",
        "border-radius": "4px"
      },
      attrs: {
        "role": "alert",
        "data-selector": 'chk-container_' + data.label.toLowerCase().split(' ').join('-')
      }
    }, [data.reviewedAt ? _c('img', {
      staticClass: "mr-4",
      attrs: {
        "src": require("@/assets/icons/ok.svg"),
        "alt": "okay",
        "data-selector": "chk-item-check-icon"
      }
    }) : _c('img', {
      staticClass: "mr-4",
      attrs: {
        "src": require("@/assets/icons/alert-docs.svg"),
        "alt": "okay",
        "data-selector": "chk-item-alert-icon"
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "el-alert__content"
    }, [_c('span', {
      staticClass: "el-alert__title is-bold",
      attrs: {
        "data-selector": "chk-item-title"
      }
    }, [_vm._v(_vm._s(data.label))]), _vm._v(" "), _c('el-tooltip', {
      staticClass: "item",
      attrs: {
        "effect": "dark",
        "placement": "bottom",
        "data-selector": "chk-item-tooltip"
      }
    }, [_c('div', {
      staticStyle: {
        "padding": "0px 2px 0px 0px"
      },
      attrs: {
        "slot": "content"
      },
      slot: "content"
    }, _vm._l(data.details, function (detail, i) {
      return _c('ul', {
        key: i
      }, [_c('li', {
        staticClass: "description text-sm break-normal",
        staticStyle: {
          "text-align": "left",
          "font-weight": "400"
        },
        attrs: {
          "data-selector": "chk-tooltip-each-detail"
        }
      }, [_vm._v("\n                        " + _vm._s(detail) + "\n                      ")])]);
    }), 0), _vm._v(" "), _c('i', {
      staticClass: "applicationAddESignCls el-icon-warning-outline"
    })]), _vm._v(" "), _c('p', {
      staticClass: "el-alert__description",
      attrs: {
        "data-selector": "chk-item-desc"
      }
    }, [_vm._v(_vm._s(data.reviewedAt ? _vm.format(new Date(data.reviewedAt), 'dd/MM/yyyy hh:mm aaa') : '--'))])], 1), _vm._v(" "), !_vm.isSettled && data.reviewedAt ? _c('a', {
      staticClass: "ml-auto font-bolder",
      class: {
        'disabled-link': !_vm.isPreSettlementStatus
      },
      staticStyle: {
        "color": "#e31111"
      },
      attrs: {
        "disabled": !_vm.isPreSettlementStatus,
        "href": "javascript:void(0)",
        "data-selector": "chk-item-unmark-btn"
      },
      on: {
        "click": function ($event) {
          return _vm.updateSettlementChecklistItem(data.name, false);
        }
      }
    }, [_vm._v(_vm._s(_vm.LABEL_UNMARK_AS_REVIEWED))]) : !_vm.isSettled && !data.reviewedAt ? _c('el-button', {
      staticClass: "ml-auto",
      class: {
        'btn-disabled': !_vm.isPreSettlementStatus
      },
      attrs: {
        "disabled": !_vm.isPreSettlementStatus,
        "type": "primary",
        "plain": "",
        "data-selector": "chk-item-mark-reviewed-btn"
      },
      on: {
        "click": function ($event) {
          return _vm.updateSettlementChecklistItem(data.name, true);
        }
      }
    }, [_vm._v(_vm._s(_vm.LABEL_MARK_AS_REVIEWED))]) : _vm._e()], 1)]);
  }), 1)], 1)]), _vm._v(" "), _c('el-card', {
    staticClass: "width-22 ml-4 application-form pb-child-none mb-4 settlement-Checklist"
  }, [_c('div', {
    staticClass: "items-center flex justify-between",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "text-xs font-extrabold"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_SETTLEMENT_NOTES) + "\n        ")])]), _vm._v(" "), _c('div', [_c('el-row', {
    attrs: {
      "gutter": 32
    }
  }, [_c('el-col', {
    staticClass: "w-full",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [!_vm.isSettled || _vm.isAdmin ? _c('el-form', {
    ref: "ruleForm",
    staticClass: "demo-ruleForm",
    attrs: {
      "model": _vm.ruleForm,
      "rules": _vm.rules
    }
  }, [_c('el-form-item', {
    staticClass: "mb-3",
    attrs: {
      "prop": "settlementNotes",
      "data-selector": "st-notes-input-container"
    }
  }, [_c('el-input', {
    attrs: {
      "type": "textarea",
      "autosize": {
        minRows: 8,
        maxRows: 10
      },
      "placeholder": "Write any notes here",
      "data-selector": "st-notes-input"
    },
    model: {
      value: _vm.ruleForm.settlementNotes,
      callback: function ($$v) {
        _vm.$set(_vm.ruleForm, "settlementNotes", $$v);
      },
      expression: "ruleForm.settlementNotes"
    }
  })], 1), _vm._v(" "), _c('el-form-item', {
    staticClass: "mb-0"
  }, [_c('el-col', {
    staticClass: "p-0",
    attrs: {
      "sm": 24,
      "lg": 12
    }
  }, [_c('div', {
    staticClass: "line-hight"
  }, [_c('p', {
    staticClass: "text-xs font-bolder"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_LAST_UPDATED_AT))]), _vm._v(" "), _c('p', {
    staticClass: "text-sm",
    attrs: {
      "data-selector": "st-notes-last-updated"
    }
  }, [_vm._v("\n                      " + _vm._s(_vm.settlementNotesUpdatedAt ? _vm.format(new Date(_vm.settlementNotesUpdatedAt), 'dd/MM/yyyy hh:mm aaa') : '--') + "\n                    ")])])]), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto mt-2 float-right",
    attrs: {
      "type": "primary",
      "disabled": _vm.shouldDisableNotes,
      "data-selector": "save-notes-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.saveChecklistNotes();
      }
    }
  }, [_vm._v("Save Notes")])], 1)], 1) : _c('div', [_c('p', {
    staticClass: "note-description text-sm mt-1"
  }, [_vm._v(_vm._s(_vm.ruleForm.settlementNotes ? _vm.ruleForm.settlementNotes : '--'))]), _vm._v(" "), _c('hr', {
    staticClass: "my-4",
    staticStyle: {
      "border": "1px solid #e8e8e8"
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "line-hight"
  }, [_c('p', {
    staticClass: "text-xs font-bolder"
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_LAST_UPDATED_AT))]), _vm._v(" "), _c('p', {
    staticClass: "text-sm",
    attrs: {
      "data-selector": "st-notes-last-updated"
    }
  }, [_vm._v("\n                  " + _vm._s(_vm.settlementNotesUpdatedAt ? _vm.format(new Date(_vm.settlementNotesUpdatedAt), 'dd/MM/yyyy hh:mm aaa') : '--') + "\n                ")])])])], 1)], 1)], 1)])], 1), _vm._v(" "), _c('p', {
    staticClass: "border-t border-light-grey mb-4"
  }), _vm._v(" "), _c('SettlementSubTab', _vm._b({}, 'SettlementSubTab', Object.assign({}, _vm.$props), false)), _vm._v(" "), _c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isDialogLoading,
      expression: "isDialogLoading"
    }],
    attrs: {
      "visible": _vm.isSettlementDailogVisible,
      "show-close": false,
      "width": "460px",
      "center": "",
      "data-selector": "submit-settlement-confirm-modal"
    },
    on: {
      "update:visible": function ($event) {
        _vm.isSettlementDailogVisible = $event;
      }
    }
  }, [_c('div', {
    staticClass: "el-dialog__header custom-dialog-header"
  }, [_c('img', {
    attrs: {
      "src": require("assets/icons/warning-employement.svg"),
      "alt": "Warning"
    }
  }), _vm._v(" "), _c('span', {
    staticClass: "text-black mt-2 font-extrabold",
    attrs: {
      "data-selector": "sscm-title"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_FOR_SUBMIT_SETTLEMENT))])]), _vm._v(" "), _c('p', {
    staticClass: "text-sm text-center",
    staticStyle: {
      "word-break": "break-word"
    },
    attrs: {
      "data-selector": "sscm-subtitle"
    }
  }, [_vm._v(_vm._s(_vm.LABEL_ARE_YOU_SURE_YOU_WANT_TO_SAVE_SETTLEMENT))]), _vm._v(" "), _c('div', {
    staticClass: "dialog-footer w-full flex justify-between",
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    attrs: {
      "type": "text",
      "data-selector": "sscm-cancel-btn"
    },
    on: {
      "click": function ($event) {
        _vm.isSettlementDailogVisible = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CANCEL))]), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "type": "primary",
      "data-selector": "sscm-yes-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.settleApplication();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_YES_PROCEED))])], 1)]), _vm._v(" "), _c('el-dialog', {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.isDialogLoading,
      expression: "isDialogLoading"
    }],
    attrs: {
      "visible": _vm.isOpenSettlementETADialog,
      "width": "30%",
      "center": "",
      "close-on-press-escape": false,
      "close-on-click-modal": false,
      "show-close": false,
      "data-selector": "settlement-eta-modal"
    },
    on: {
      "update:visible": function ($event) {
        _vm.isOpenSettlementETADialog = $event;
      }
    }
  }, [_c('div', {
    staticClass: "custom-dialog-header"
  }, [_c('span', {
    staticClass: "custom-header mb-6 pt-0 px-0 d-block"
  }, [_c('div', {
    staticClass: "clearfix items-center flex justify-between",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('p', {
    staticClass: "font-bolder"
  }, [_vm._v(_vm._s(_vm.LABEL_SETTLEMENT_ETA))])])])]), _vm._v(" "), _c('div', [_c('el-form', {
    ref: "settlementETAform",
    attrs: {
      "model": _vm.settlementETAData
    }
  }, [_c('el-row', [_c('el-col', [_c('el-form-item', {
    attrs: {
      "label": _vm.LABEL_FOR_DATE,
      "prop": "settlementETA",
      "required": "true",
      "data-selector": "m-settlement-eta-input-container"
    }
  }, [_c('el-date-picker', {
    attrs: {
      "type": "date",
      "format": "dd/MM/yyyy",
      "placeholder": "DD/MM/YYYY"
    },
    model: {
      value: _vm.settlementETAData.settlementETA,
      callback: function ($$v) {
        _vm.$set(_vm.settlementETAData, "settlementETA", $$v);
      },
      expression: "settlementETAData.settlementETA"
    }
  })], 1)], 1)], 1), _vm._v(" "), _c('div', {
    staticClass: "dialog-footer mt-4 pt-4 border-t border-light-grey w-full flex justify-between"
  }, [_c('el-button', {
    attrs: {
      "type": "text",
      "data-selector": "m-set-eta-cancel-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.openCloseSettlementETADialog();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_CANCEL))]), _vm._v(" "), _c('el-button', {
    staticClass: "w-auto",
    attrs: {
      "type": "primary",
      "data-selector": "m-set-eta-update-btn"
    },
    on: {
      "click": function ($event) {
        return _vm.updateSettlementETA();
      }
    }
  }, [_vm._v(_vm._s(_vm.LABEL_UPDATE))])], 1)], 1)], 1)]), _vm._v(" "), _c('MissingDocumntDialog', _vm._b({
    attrs: {
      "dialog-opened": _vm.ShouldDisplayMissingDocumentDialog,
      "errMessage": _vm.errMessage,
      "isFromSettlement": true
    },
    on: {
      "closeMissingDocDialog": _vm.closeMissingDocDialog
    }
  }, 'MissingDocumntDialog', Object.assign({}, _vm.$props), false)), _vm._v(" "), _c('CpdSendConfirmDialog', {
    attrs: {
      "dialog-opened": _vm.openSendProposalConfirmation,
      "cpd-url": _vm.cpdUrl,
      "is-pre-settlement": _vm.isPreSettlement,
      "is-settlement": _vm.isSettlement,
      "is-pre-settlement-update": _vm.isPreSettlementUpdate,
      "submit-button-label": _vm.SAVE_AND_SEND_LABEL,
      "isFromSettlement": true
    }
  }), _vm._v(" "), _c('MissingFieldsModal', {
    attrs: {
      "dialog-opened": _vm.openMissingFieldsModal,
      "title": _vm.LABEL_TITLE_FOR_MISSING_FIELDS_MODEL,
      "subtitle": _vm.LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_FIRST + '<b> ' + _vm.APPLICATION_STATUS_SETTLED + '</b>' + _vm.LABEL_SUBTITLE_FOR_MISSING_FIELDS_MODEL_SECOND,
      "is-commercial": _vm.isCommercial,
      "status": _vm.APPLICATION_STATUS_SETTLED,
      "errors-data": _vm.errorsdata,
      "application-data": _vm.sharedData,
      "isFromSettlement": true
    },
    on: {
      "closeMissingFieldsModalSettlement": _vm.closeMissingFieldsModalSettlement
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }