//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChangeDocumentTypeDialog from './UtilComponents/ChangeDocumentTypeDialog.vue'
import {
  LABEL_DOCUMENT_DROP,
  LABEL_DOCUMENT_UPLOAD,
  LABEL_DOCUMENT_PRIMARY,
  LABEL_DOCUMENT_SECONDARY,
  LABEL_SHARED,
  DEFAULT_DOCUMENT_TYPES,
  LABEL_UPLOADED_AT,
  LABEL_REQUESTED_AT,
  LABEL_PDF,
  LABEL_FILE_TYPE,
  LABEL_CHANGE_DOCUMENT_TYPE,
  DESCRIPTION_FOR_CHANGE_DOCUMENT_TYPE,
  LABEL_NEW_DOCUMENT_TYPE,
  LABEL_ADDED_AT,
  LABEL_REQUESTED_VIA_EMAIL,
  LABEL_DOCUMENT_SETTLEMENT,
  LABEL_SETTLEMENT_PRIMARY,
  LABEL_SETTLEMENT_SECONDARY,
  LABEL_YES_PROCEED,
  LABEL_CANCEL,
  LABEL_DELETE_DOCUMENT,
  LABEL_DELETE_DOCUMENT_TYPE,
  LABEL_DELETE_DOCUMENT_DESCRIPTION,
  LABEL_DELETE_DOCUMENT_TYPE_DESCRIPTION,
  LABEL_ELECTRONIC_BACK_STATEMENT_LAST_3_MONTHS_PURPOSE,
  LABEL_ELECTRONIC_BACK_STATEMENT_LAST_6_MONTHS_PURPOSE,
  LABEL_ELECTRONIC_BACK_STATEMENT_LAST_12_MONTHS_PURPOSE,
  LABEL_CONTINUE,
  LABEL_DELETE_DOCUMENT_CUSTOMER_DASHBOARD,
  LABEL_DELETE_DOCUMENT_TYPE_DESCRIPTION_ALSO_CUSTOMER_DASHBOARD,
  LABEL_FOR_READY,
} from '@/constants/applications'
import { format } from 'date-fns'
import { getSignedURL } from '@/utils/url'
import {
  ALL,
  TITLE_FOR_OTHER,
  USER_SESSION_DATA_KEY,
  USER_TYPE_ADMIN,
  USER_TYPE_SUPER_ADMIN,
  USER_TYPE_AGENT,
  USER_TYPE_SETTLEMENT,
  APPLICATION_STATUS_SETTLED,
  APPLICATION_STATUS_WITHDRAWN,
  APPLICATION_STATUS_DECLINED,
} from '~/constants'
import { validateUserRoles } from '~/helpers/user'

export default {
  components: {
    ChangeDocumentTypeDialog,
  },
  props: {
    documentId: {
      type: String,
      default: () => null,
    },
    documentName: {
      type: String,
      default: () => null,
    },
    documentPurpose: {
      type: String,
      default: () => null,
    },
    documentExtraName: {
      type: String,
      default: () => null,
    },
    documentRequestedDate: {
      type: String,
      default: () => null,
    },
    documentUploadedDate: {
      type: String,
      default: () => null,
    },
    documentNum: {
      type: Number,
      default: () => null,
    },
    dialogFor: {
      type: String,
      default: () => null,
    },
    primaryApplicantData: {
      type: Object,
      default: () => null,
    },
    documentData: {
      type: Array,
      default: () => [],
    },
    secondaryIndex: {
      type: Number,
      default: 0,
    },
    isPrimary: {
      type: Boolean,
      default: true,
    },
    isApplicationSettled: {
      type: Boolean,
      default: false,
    },
    isFromSettlementTab: {
      type: Boolean,
      default: false,
    },
    documentFor: {
      type: String,
      default: null,
    },
    documentRequestedViaEmail: {
      type: Boolean,
      default: false,
    },
    optionForDocument: {
      type: String,
      default: null,
    },
    applicationStatus: {
      type: String,
      default: null,
    },
    primaryDocumentData: {
      type: Array,
      default: () => [],
    },
    sharedDocumentData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      format,
      LABEL_DOCUMENT_DROP,
      LABEL_DOCUMENT_UPLOAD,
      LABEL_DOCUMENT_PRIMARY,
      LABEL_DOCUMENT_SECONDARY,
      USER_TYPE_AGENT,
      USER_SESSION_DATA_KEY,
      LABEL_SHARED,
      LABEL_UPLOADED_AT,
      LABEL_REQUESTED_AT,
      DEFAULT_DOCUMENT_TYPES,
      LABEL_FILE_TYPE,
      TITLE_FOR_OTHER,
      filesUploaded: [],
      filesToUpload: [],
      LABEL_ADDED_AT,
      LABEL_REQUESTED_VIA_EMAIL,
      pathToUpdate: '/assets/signed-url',
      LABEL_CHANGE_DOCUMENT_TYPE,
      DESCRIPTION_FOR_CHANGE_DOCUMENT_TYPE,
      openChangeDocumentTypeDialog: false,
      LABEL_NEW_DOCUMENT_TYPE,
      loggedUserType: null,
      isUploaded: false,
      USER_TYPE_SETTLEMENT,
      LABEL_DOCUMENT_SETTLEMENT,
      APPLICATION_STATUS_SETTLED,
      LABEL_SETTLEMENT_PRIMARY,
      LABEL_SETTLEMENT_SECONDARY,
      ALL,
      LABEL_YES_PROCEED,
      LABEL_CANCEL,
      LABEL_DELETE_DOCUMENT,
      LABEL_DELETE_DOCUMENT_TYPE,
      LABEL_DELETE_DOCUMENT_DESCRIPTION,
      LABEL_DELETE_DOCUMENT_TYPE_DESCRIPTION,
      LABEL_CONTINUE,
      LABEL_DELETE_DOCUMENT_CUSTOMER_DASHBOARD,
      LABEL_DELETE_DOCUMENT_TYPE_DESCRIPTION_ALSO_CUSTOMER_DASHBOARD,
      isRemoveDialogForAll: '',
      removeDocumentName: '',
      dialogOpened: false,
      isDialogLoading: false,
      selectedDocumentForRemove: {},
      customDebounceDoc: null,
      isLoading: false,
    }
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_SERVER_URL
    },
    token() {
      return localStorage.getItem('auth._token.local')
    },
    isTypeAgent() {
      const userRoles = this.$auth.$storage.getUniversal(
        USER_SESSION_DATA_KEY
      )?.systemRoleTypes
      return validateUserRoles(userRoles, [USER_TYPE_AGENT])
    },
    isSettlementAgent() {
      const userRoles = this.$auth.$storage.getUniversal(
        USER_SESSION_DATA_KEY
      )?.systemRoleTypes
      return validateUserRoles(userRoles, [USER_TYPE_SETTLEMENT])
    },
    isTypeAdmin() {
      const userRoles = this.$auth?.user?.systemRoleTypes
      return (
        validateUserRoles(userRoles, [
          USER_TYPE_ADMIN,
          USER_TYPE_SUPER_ADMIN,
        ]) &&
        ![
          LABEL_ELECTRONIC_BACK_STATEMENT_LAST_3_MONTHS_PURPOSE,
          LABEL_ELECTRONIC_BACK_STATEMENT_LAST_6_MONTHS_PURPOSE,
          LABEL_ELECTRONIC_BACK_STATEMENT_LAST_12_MONTHS_PURPOSE,
        ].includes(this.documentPurpose)
      )
    },
    shouldShowDeleteButton() {
      return (
        this.isTypeAdmin ||
        this.isSettlementAgent ||
        ![
          APPLICATION_STATUS_SETTLED,
          APPLICATION_STATUS_WITHDRAWN,
          APPLICATION_STATUS_DECLINED,
        ].includes(this.applicationStatus)
      )
    },
  },
  watch: {
    documentData() {
      this.updateListData()
    },
  },
  created() {
    this.updateListData()
  },
  mounted() {
    this.$nuxt.$on('close-change-document-type-dialog', () => {
      this.openChangeDocumentTypeDialog = false
    })
  },
  methods: {
    async checkUserAuthentication(path) {
      const response = await this.$axios.get('/user/me', {
        headers: {
          'x-access-token': this.$auth.strategy.token.get(),
        },
      })

      if (response?.data && response?.status === 200) {
        window.open(path, '_blank')
      }
    },
    checkSettlementCondition() {
      return (
        this.isFromSettlementTab ||
        this.documentFor === this.LABEL_SETTLEMENT_PRIMARY ||
        this.documentFor === this.LABEL_SETTLEMENT_SECONDARY
      )
    },
    editDocuments() {
      this.openChangeDocumentTypeDialog = true
    },
    getFileToUploadByIndexUUID(uuid) {
      return this.filesToUpload.findIndex((file) => file.uuid === uuid)
    },
    downloadFile(response, file) {
      let headers = response.headers
      let blob = new Blob([response.data], { type: headers['content-type'] })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = file.filename
      link.click()
      link.remove()
    },
    download(file) {
      if (file) {
        this.$axios({
          method: 'get',
          url: getSignedURL(file.url, this.$auth),
          responseType: LABEL_FILE_TYPE,
        }).then((response) => {
          this.downloadFile(response, file)
        })
      }
    },
    updateListData() {
      this.filesUploaded = [...this.documentData]
        .map((doc) => {
          if (
            doc.purpose !== TITLE_FOR_OTHER &&
            doc.selectedDocumentId === this.documentId &&
            !doc.deletedAt
          ) {
            return {
              id: doc._id,
              name: doc.name ?? doc?.purpose,
              url: doc.url,
              filename: doc.filename,
              objectKey: doc.objectKey,
            }
          } else if (
            doc.purpose === TITLE_FOR_OTHER &&
            doc.name === this.documentExtraName &&
            doc.selectedDocumentId === this.documentId &&
            !doc.deletedAt
          ) {
            return {
              id: doc._id,
              purpose: doc?.purpose,
              filename: doc?.filename,
              name: doc?.name ?? doc?.purpose,
              url: doc?.url,
              objectKey: doc.objectKey,
            }
          }
        })
        .filter((doc) => doc?.url)
    },
    isPDF(file) {
      return file?.filename?.split('.').pop() === LABEL_PDF.toLowerCase()
    },
    async newUploadDocuments(file, uploadFileList) {
      try {
        const { id, applicationId } = this.$route.params
        let formData = new FormData()
        const fileList = uploadFileList.filter(
          (doc) => doc.status === LABEL_FOR_READY
        )
        for (let i = 0; i < fileList.length; i++) {
          formData.append(`files[${i}]`, fileList[i].raw)
        }
        formData.append(
          'document',
          JSON.stringify({
            purpose: this.documentPurpose,
            name: this.documentExtraName,
            selectedDocumentId: this.documentId || '',
            ...(this.checkSettlementCondition() && {
              documentFor: this.LABEL_DOCUMENT_SETTLEMENT,
            }),
          })
        )
        this.isLoading = true
        clearTimeout(this.customDebounceDoc)
        this.customDebounceDoc = setTimeout(async () => {
          await this.$store.dispatch('applications/uploadDocument', {
            formData,
            id,
            applicationId,
            applicantId: this.primaryApplicantData._id,
            isShared: [LABEL_SHARED, LABEL_DOCUMENT_SETTLEMENT].includes(
              this.dialogFor
            ),
          })
          this.isLoading = false
          this.$refs.upload.clearFiles()
        })
      } catch (e) {
        this.$refs.upload.clearFiles()
        console.log(e)
        this.isLoading = false
        this.$toasted.error(
          axiosErrorMessageExtractor(e, 'Could not upload file')
        )
      }
    },
    async openCloseConfirmationDialog() {
      this.dialogOpened = !this.dialogOpened
    },
    async openConfirmationDialog(file, type = '') {
      if (type === ALL) {
        this.removeDocumentName = file
      } else {
        this.selectedDocumentForRemove = file
      }
      this.isRemoveDialogForAll = type === ALL
      this.openCloseConfirmationDialog()
    },
    async confirmDelete() {
      this.isDialogLoading = !this.isDialogLoading
      if (this.isRemoveDialogForAll) {
        await this.removeUpload()
      } else {
        await this.handleRemove(this.selectedDocumentForRemove)
      }
      this.isDialogLoading = !this.isDialogLoading
      this.openCloseConfirmationDialog()
    },
    async handleRemove(file) {
      const { id, applicationId } = this.$route.params
      const uuID = Math.random().toString().slice(2, 11)
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      await this.$store.dispatch(`applications/deleteUploadedDocument`, {
        id,
        applicationId,
        applicantId: this.primaryApplicantData._id,
        documentId: file.id,
        isShared: [LABEL_DOCUMENT_SETTLEMENT, LABEL_SHARED].includes(
          this.dialogFor
        ),
      })
    },
    async removeUpload() {
      this.$nuxt.$emit('startDocumentLoader')
      const { id, applicationId } = this.$route.params
      const uuID = Math.random().toString().slice(2, 11)
      sessionStorage.setItem('uniquePatchRequestID', uuID)
      await this.$store.dispatch(`applications/deleteSelectedDocument`, {
        id,
        applicationId,
        documentId: this.documentId,
        applicantId: this.primaryApplicantData._id,
        isShared: [LABEL_DOCUMENT_SETTLEMENT, LABEL_SHARED].includes(
          this.dialogFor
        ),
      })
      this.filesUploaded = []
      // eslint-disable-next-line array-callback-return
      this.documentData.map((doc) => {
        // eslint-disable-next-line no-unused-expressions
        !doc.deletedAt &&
        doc.selectedDocumentId === this.documentId &&
        (doc.name === this.documentName || doc.purpose === this.documentPurpose)
          ? this.filesUploaded.push({
              name: doc.name,
              url: doc.url,
              filename: doc.filename,
              id: doc._id,
            })
          : true
      })
      this.$nuxt.$emit('stopDocumentLoader')
    },
    getMappedNameWithPurpose(documentName) {
      const result = this.DEFAULT_DOCUMENT_TYPES.filter(
        (type) => type.value === documentName
      )
      return result && result[0] ? result[0].key : documentName
    },
  },
}
