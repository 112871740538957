import { render, staticRenderFns } from "./AssetsAndLiabilitiesSubTab.vue?vue&type=template&id=640face6&"
import script from "./AssetsAndLiabilitiesSubTab.vue?vue&type=script&lang=js&"
export * from "./AssetsAndLiabilitiesSubTab.vue?vue&type=script&lang=js&"
import style0 from "./AssetsAndLiabilitiesSubTab.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormattedNumericInput: require('/codebuild/output/src1023908354/src/cc-portal/components/FormattedNumericInput.vue').default})
